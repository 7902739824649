<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Reset Password</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <div class="field">
            <label class="label">Enter your email to reset your password</label>
            <div class="control has-icons-right">
              <input v-model.trim="email" type="email" class="input" readonly/>
              <span class="icon is-small is-right">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
            <p v-if="errorMsg !== ''" class="help is-danger">{{ errorMsg }}</p>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          @click="resetPassword()"
          :class="{ 'is-loading': loading }"
          class="button is-primary"
        >
          Reset
        </button>
        <button class="button is-dark" @click="closeModal()">Cancel</button>
      </footer>
    </div>
    <div class="modal" :class="{ 'is-active': showSuccess }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Success</p>
        </header>
        <section class="modal-card-body">
          <p>Check your email for the password reset link.</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-dark" @click="showSuccess = false">
            Close
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase";

export default {
  name: "PasswordResetMyAccount",
  props: ["active"],
  data() {
    return {
      email: auth.currentUser.email,
      loading: false,
      showSuccess: false,
      errorMsg: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async resetPassword() {
      this.loading = true;
      this.errorMsg = "";
      try {
        await auth.sendPasswordResetEmail(this.email);
        this.showSuccess = true;
      } catch (err) {
        this.errorMsg = err.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 100%;
}
.button.is-ghost {
  color: black;
  &:hover {
    color: black;
  }
}
.brand-logo {
  height: 40px;
  max-height: none;
}
</style>
