<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Subscription Required</p>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>This feature is only available to active subscribers. Choose & activate a plan to get access.</p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-dark" @click="closeModal()">Understood</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentRequiredModal',
  props: ['active'],
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  width: 100%;
}
.button.is-ghost {
  color: black;
  &:hover {
    color: black;
  }
}
.brand-logo {
  height: 40px;
  max-height: none;
}
</style>
